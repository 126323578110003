@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');

a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
}

html,
body,
#__next {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    scrollbar-width: thin !important;
    scrollbar-color: #444 #666 !important;
}

.white {
    color: white !important
}

.bold {
    font-weight: bold !important;
}

.boldy {
    font-weight: 500;
}

.word-wrap {
    word-wrap: anywhere;
}

.unstyle-anchors  a  {
    color: inherit;
    text-decoration: none;
}    


.display-block {
    display: block;
}

.noUnderline {
    text-decoration: none;
}